/* FormComponent.css */

.form-container {
    color: white;
    height: 100vh;
    background-image: url('assets/image/gradient-blue-background_23-2149350175_waifu2x_noise2_scale4x.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

h1,
h2 {
    color: white;
}

a {
    color: #ffc107;
}

a:hover {
    text-decoration: underline;
}

.form-group {
    position: relative;
    margin-bottom: 1.5rem;
}

.input {
    width: 100%;
    padding: 1rem 0.75rem;
    font-size: 1rem;
    color: white;
    border: none;
    border-radius: 0px;
}

.input:focus {
    outline: none;
    border-color: #ffffff;
    box-shadow: none;
    /* Remove the default blue glow */
}

.input:focus+label,
.input:not(:placeholder-shown)+label {
    transform: translateY(-1.5rem);
    font-size: 0.875rem;
    color: #ffffff;
}

.label {
    position: absolute;
    top: 10px;
    /* left: 13rem; */
    color: #ffffff;
    transition: all 0.2s ease-in-out;
    pointer-events: none;
    padding: 0 0.25rem;
}


/* Adjust padding for label */

.input::placeholder {
    color: transparent;
}

.input:focus {
    border-color: rgba(100, 100, 100, 0)!important;
    -webkit-box-shadow: none!important;
    -moz-box-shadow: none!important;
    box-shadow: none!important;
    border-bottom: 1px solid rgb(255, 255, 255) !important;
}

#url {
    background: none transparent;
    border-bottom: 1px solid rgb(255, 255, 255);
    color: white;
    border-radius: 0%;
}

#email {
    background: none transparent;
    border-bottom: 1px solid rgb(255, 255, 255) !important;
    color: white;
    border-radius: 0%;
}

.input {
    border: none !important;
}

.text {
    color: red;
}


/* login From */

.login {}

.email {
    border: 1px solid black !important;
}

.email:focus {
    border: 1px solid black !important;
}

.pass:focus {
    border: 1px solid black !important;
}

.pass {
    border: 1px solid black !important;
}

.section {
    height: 100vh;
    background-image: url('assets/image/[Downloader.la]-66508d9b8b795.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.card {
    background-color: #7c37df !important;
    border-radius: 15px !important;
    border: none !important;
    color: white;
}

.label {
    left: 0rem;
}

#password {
    background: none transparent;
    border-bottom: 1px solid rgb(255, 255, 255);
    color: white;
    border-radius: 0%;
}

@media only screen and (max-width: 767px) {
    .form-container {
        height: 600px;
    }
}


/* @media only screen and (min-width: 320px) {
    
} */

@media only screen and (max-width: 320px) {
    .form-container {
        height: 680px;
    }
}

.main-container {
    padding: 24px 20px 0px 37px;
}

.page-header {
    padding: 15px;
    border-radius: 0px;
    background-color: white;
}

.breadcrumb {
    margin-bottom: 1px !important;
}

.page-body {
    max-width: 1056px;
    overflow: auto;
}

.r {
    --bs-gutter-x: 0rem !important;
}